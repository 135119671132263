import { Link, graphql } from "gatsby";
import React from 'react';
import styles from "../../components/innerPage.module.css"
import Footer from '../../components/Investor/footer';
import Header from '../../components/Investor/header';
import { useDispatch } from "react-redux"
import ScrollAnimation from 'react-animate-on-scroll';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import { showInvestorDisclaimerPopup } from "../../actions/investor/prompts/disclaimers"
import { toggleInvestorDisclaimerPopup } from "../../controllers/investor/disclaimer"
import { sortByLatestYear, addDisclaimersToCmsLinks, capitalizeFirstLetter, isNullUndefined, richTextOptions, replaceNewlinesWithHtmlLineBreaks } from "../../util/index"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultInvestorDisclaimerPopupConfigs, config } from '../../util/configs'
import queryString from "query-string"
import SEO from "../../components/seo"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper";
import DellasDiclaimer from "../../components/Investor/dellas";

import { Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const pageQueryTabsForFilesTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulAccordionForFilesTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            bannerImage {
                file {
                    url
                }
            }
            spaceId
            slug
            title
           disclaimerTitle
  disclaimerText {
      childContentfulRichText {
        html
      }
    }


            accordion {
                title
                file {
                  url
                }
                description
                spaceId
            }
           
            bodyText {
      childContentfulRichText {
        html
      }
    }
        }
    }
`

const AccordionForFilesTemplatePageInvestor = (props) => {
    const dispatch = useDispatch();
    const [dellasAccepted, setDellasAccepted] = React.useState(false)

    const { bannerImage, slug, title, spaceId, parentMenu, accordion, bodyText, disclaimerText, disclaimerTitle, topSectionText } = props.data.contentfulAccordionForFilesTemplate;

    console.log(disclaimerText,"DS")
    let uniqueYearsForTabs = isNullUndefined(accordion) ? [] : accordion.reduce((acc, accordion) => {
        if (!acc.includes(accordion.title)) {
            acc.push(accordion.title);
        }
        return acc;
    }, []).sort(sortByLatestYear);

    let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)
console.log(richText)
    

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open(externalUrl, "_blank")
        let closeCallback = () => { }
        let actionConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showInvestorDisclaimerPopup(actionConfig));
    }
    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);
    let [defaultActiveTab, setDefaultActiveTab] = React.useState(`${uniqueYearsForTabs[0]}`);

    const showDallasDisclaimer = () => {
        window.sessionStorage.setItem("Accepted", true)
        window.location.reload(false);

    }
    
    React.useEffect(() => {
        setDellasAccepted(sessionStorage.getItem("Accepted"))
        let { veevaDisclaimer, veevaTab } = queryString.parse(props.location.search || "");

        if (veevaDisclaimer) {
            setVeevaDisclaimer(true);
            showExternalLinkDisclaimer(config.defaultBaseUrl)
        }

        if (veevaTab) {
            setDefaultActiveTab(veevaTab);
        }

    }, []);

    const Accordion = withStyles({
        root: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'block',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    })(MuiAccordion);

    const AccordionSummary = withStyles({
        root: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            marginBottom: -1,
            minHeight: 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {},
    })(MuiAccordionSummary);

    const AccordionDetails = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiAccordionDetails);

    return (
        <TemplateWrapper>
            <Header />

            <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />

            <div className={styles.banner} style={{ background: `url(${isNullUndefined(bannerImage) ? "" : bannerImage.file.url})` }}>
                <div className={styles.overlay}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            <h1 className="">{title}</h1>
                            <ol className="custom_breadcrumb">
                                <li className="breadcrumb-item"><Link to="/Investor/home">Home</Link></li>
                                {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.tabs_for_files_template) && <li className="breadcrumb-item"><a href="#">
                                    {parentMenu.tabs_for_files_template[0].parentMenu.title}
                                </a></li>}
                                <li className="breadcrumb-item active" aria-current="page">
                                    {title}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.content}>
                <div className="container pt-5 pb-5">
                    {!dellasAccepted ? <> <div className="container">
                        <div className={`row text-center`}>

                            <div className="col-12 col-sm-12 col-md-12 disclaimer_accepted_content">

                                <h3>{disclaimerTitle}</h3>

                                <p >
                                    <div dangerouslySetInnerHTML={{ __html: disclaimerText?.childContentfulRichText?.html || "" }} />
                                </p>




                            </div>
                        </div>
                    </div> <div className="row mb-4">

                            <div className='col-12 col-sm-12 col-md-6 text-md-right text-sm-center'>
                                <button onClick={showDallasDisclaimer} className='btn green'>I Agree</button>
                               
                            </div>
                            <div className='col-12 col-sm-12 col-md-6 text-md-left text-sm-center'>
                                <Link to="/Investor/home"> <button className='btn red'>I Disagree</button></Link>
                            </div>
                        </div> </>
                        : <>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">

                                    <p >
                                        <div dangerouslySetInnerHTML={{ __html: bodyText?.childContentfulRichText?.html || "" }} />
                                    </p>

                                   
                                        <div className="dallas mt-4">

                                            {(() => {
                                                let tabsContent = [];

                                                for (let year of uniqueYearsForTabs) {
                                                    for (let tab of accordion) {
                                                        if (tab.title === year) {
                                                            tabsContent.push(tab);
                                                        }
                                                    }
                                                }

                                                return uniqueYearsForTabs.map(year => {
                                                    return (

                                                        <Accordion key={year} tabId={year}>
                                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                                <Typography>{year}</Typography>
                                                            </AccordionSummary>
                                                            <ul>
                                                                {tabsContent.filter(item => item.title === year).map((document) => {
                                                                    return (

                                                                           
                                                                        
                                                                            <li className="disclaimer_description" key={document.title}>
                                                                                <a href={document.file.url} target="blank">
                                                                                    <AccordionDetails>
                                                                                        <Typography>
                                                                                            <div dangerouslySetInnerHTML={{ __html: replaceNewlinesWithHtmlLineBreaks(document.description) }} />
                                                                                        </Typography>
                                                                                    </AccordionDetails>
                                                                                </a>
                                                                            </li>
                                                                       
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Accordion>

                                                    )
                                                })

                                            })()}

                                        </div>


                                </div>


                            </div>

                        </>}





                </div>

            </div>

            <Footer />
        </TemplateWrapper>
    );
}

export default AccordionForFilesTemplatePageInvestor;