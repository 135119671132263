import React from 'react';
import styles from "../../ourvision.module.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby"
import { isNullUndefined, addDisclaimersToCmsLinks, richTextOptions } from "../../../util"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"

export default (props) => {
    const { bottomExcerptText, bottomExcerptButtonUrl, bottomExcerptImage } = props.queryData || {};

    let bottomExcerptTextRichText = documentToReactComponents(isNullUndefined(bottomExcerptText) ? bottomExcerptText : bottomExcerptText.json, richTextOptions);

    let bottomExcerptTextHtml = ReactDOMServer.renderToStaticMarkup(bottomExcerptTextRichText);
    return (
        <>
        {  bottomExcerptImage && bottomExcerptImage.file && bottomExcerptImage.file.url ? 
        <div className={styles.our_vision} style={{ backgroundImage: bottomExcerptImage && bottomExcerptImage.file && bottomExcerptImage.file.url ? `url(${bottomExcerptImage.file.url})` : '' }}>

            <div className={styles.overlay}></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <ScrollAnimation animateIn="fadeInUp">
                            {ReactHtmlParser(bottomExcerptTextHtml, { transform: (node, index) => addDisclaimersToCmsLinks(node, index, null) })}
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp">
                            <Link to={bottomExcerptButtonUrl}>
                                <button className={`${styles.custom_btn} ${styles.bordered_btn}`}>
                                    <span>View more </span>
                                </button>
                            </Link>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
        : ''
}
        </>
    );
}