import React from 'react';
import CompanyBio from "../../images/bio.png"
import styles from "../bio.module.css"
import ScrollAnimation from 'react-animate-on-scroll';

class Dellas extends React.Component {
    state = {}
    render() {
        return (
            <div className={styles.bio_wrap}>
               
            </div>
        );
    }
}

export default Dellas;                                                                                                     