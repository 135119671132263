import React from 'react';
import styles from "../../components/landingPage.module.css"
import SEO from "../../components/seo"
import ScrollAnimation from 'react-animate-on-scroll';
import SiteVersion from '../changeSites';
import MainLandingDisclaimerPopup from "../../components/prompts/disclaimers/main-landing/disclaimer-popup"
import { getDefaultMainLandingDisclaimerPopupConfigsInvestors, getDefaultMainLandingDisclaimerPopupConfigsUKResidents, getDefaultMainLandingDisclaimerPopupConfigsNonUKResidents, config} from "../../util/configs"
import {ValidateRoute } from "../../util/route-helper";
import StraplineBanner from '../_shared/topStrip/topstrip';
import LandingPageTopStrap from '../_shared/landingPageTopStrap/topstrip';

class MainLanding extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            popupConfig: null
        }
    }
    componentDidMount(){
        ValidateRoute();
    }
    toggleMainLandingDisclaimerPopup = (path, site) => {
        let popupConfig = {}
        switch(site){
            case "UkResidents":
                popupConfig = getDefaultMainLandingDisclaimerPopupConfigsUKResidents(path);
                localStorage.setItem("UkResidents-Landing", JSON.stringify( { disclaimerShown: true}));
                break;
            case "NonUkResidents":
                popupConfig = getDefaultMainLandingDisclaimerPopupConfigsNonUKResidents(path);
                localStorage.setItem("NonUkResidents-Landing", JSON.stringify({ disclaimerShown: true }));
                break;
            // case "Investors":
            //     popupConfig = getDefaultMainLandingDisclaimerPopupConfigsInvestors(path);
            //     localStorage.setItem("investor-home", JSON.stringify({ disclaimerShown: true }));
            //     break;
        }
        this.setState({popupConfig})
    }

    render() {
        const {popupConfig} = this.state;
        return (
            <React.Fragment>
                <SEO title={"Home"} />
                <div className={styles.landing_wrap}>
               
                 <div className={styles.landing_logo}>
                  
              <div className="container">
    
                        <span className={styles.logo_primary}></span>
                      
                    </div>
                   
              </div>
              <LandingPageTopStrap/>
                <div className="container">
                <SiteVersion/>
                {popupConfig && popupConfig.show && <MainLandingDisclaimerPopup popupConfig={popupConfig} hide={() => this.setState({popupConfig: null})} />}
                    
                    <div className="row">
                        <div className={`col-12 col-sm-12 col-md-12 ${styles.welcome_section}`}>
                       
                        <ScrollAnimation animateIn="fadeInUp" delay={100}>
                            <h2>Dedicated to <span>developing</span> hormone therapeutics</h2>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" delay={700}>
                            <p>A global pharmaceutical company focused on chronic endocrine conditions</p>
                       </ScrollAnimation>
                        </div>
                      </div>
                    <div className="row">
                        <div className={`col-12 col-sm-12 col-md-12 col-lg-4  ${styles.our_services}`}>
                        <ScrollAnimation animateIn="fadeIn">
                                    <div onClick={() => this.toggleMainLandingDisclaimerPopup(`${config.ukResidentsBaseUrl}/UkResidents/Landing`, "UkResidents")} >
                            <div className={`row ${styles.landing_page_sections}`}>
                                <div className="col-12 col-sm-12 col-md-12  pl-0">
                                    <div className={styles.card_border}>
                                    <div className={styles.card}>
                                        <div className={styles.content}>
                                            <h2 className={styles.title}>UK<br/> Residents</h2>
                                        </div>
                                     </div>
                                    </div>
                                </div>
                                
                            </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                        <div className={`col-12 col-sm-12 col-md-12 col-lg-4   ${styles.our_services}`}>
                        <ScrollAnimation animateIn="fadeIn">
                                    <div onClick={() => this.toggleMainLandingDisclaimerPopup(`${config.nonUkResidentsBaseUrl}/NonUkResidents/Landing`, "NonUkResidents")}> 
                        <div className={`row ${styles.landing_page_sections} ${styles.non_health}`}>
                                <div className="col-12 col-sm-12 col-md-12 pl-0">
                                <div className={styles.card_border}>
                                        <div className={styles.card}>
                                        <div className={styles.content}>
                                        <h2 className={styles.title}>Non-UK<br/> Residents</h2>
                                       </div>
                                 </div>
                                </div>
                                   
                                 </div>
                                
                            </div>
                        </div>
                    </ScrollAnimation>
                        </div>
                    


                         

                    </div>
                </div>
            </div>
            </React.Fragment>
         );
    }
}

export default MainLanding;