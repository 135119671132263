
import { Link, graphql } from "gatsby";
import React, {useState} from 'react';
import { useDispatch } from 'react-redux'
import styles from "../../components/innerPage.module.css"
import Layout from '../../components/Investor/layout';
import { Auth } from 'aws-amplify'
import ScrollAnimation from 'react-animate-on-scroll';
import { deriveHomePathFromSpace, isNullUndefined, getMomentDate, richTextOptions, capitalizeFirstLetter  } from "../../util"
import Pdf_icon from '../../images/pdf_icon.png'
import Mic_icon from '../../images/mic_icon.png'
import Video_icon from '../../images/Video-icon.png'
import {showInvestorAuthFormPopup} from "../../actions/investor/prompts/forms/"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultInvestorDisclaimerPopupConfigs } from '../../util/configs'
import SEO from "../../components/seo"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

export const pageQueryReportsTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulReportsTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            bannerImage {
                file {
                    url
                }
            }
            spaceId
            slug
            title
            parentMenu {
                reports_template {
                  parentMenu {
                    title
                    slug
                  }
                }
            }
            financial_reports_item {
                date
                title
                slug
                audio {
                    file {
                        url
                    }
                }
                presentation {
                    file {
                        url
                    }
                }
                report {
                    file {
                        url
                    }
                }
                webcast {
                    file {
                      url
                    }
                  }
            }
        }
    }
`

const ReportsTemplatePageInvestor = (props) => {
    let [user, setUser] = useState(null);
    const dispatch = useDispatch()
    const {bannerImage, slug, title, spaceId, parentMenu, financial_reports_item} = props.data.contentfulReportsTemplate;
    React.useEffect(() => {
    }, []);
    let pagePath = props.location.pathname;

    const homePath = (() => deriveHomePathFromSpace(spaceId))()

    const showDocument = async (e, fileUrl) => {
        e.preventDefault()

        if(isNullUndefined(fileUrl)){
            return;
        }

        let isLoggedIn = false;
        try {
            let user = await Auth.currentAuthenticatedUser();
            isLoggedIn = true;
            setUser(user);
        }catch(e){
            isLoggedIn = false;
        }
        
        if(isLoggedIn){
            if(!isNullUndefined(fileUrl)){
                window.open(fileUrl, "_blank")
            }
            return;
        }

        let actionConfig = {
            show: true,
            okLabel: "Ok",
            fileUrl: fileUrl,
            path: pagePath,
            closeLabel: "Cancel",
            okCallback: () => {},
            closeCallback: () => {}
        }

        dispatch(showInvestorAuthFormPopup(actionConfig))
    }

    const sortItemsByLatestDate = (a, b) => {
        let date1 = (getMomentDate(a.date)).valueOf();
        let date2 = (getMomentDate(b.date)).valueOf();
        return date2 - date1
    }

  return (
    <TemplateWrapper>
        <Layout>
         <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
          
                <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
                <div className={styles.overlay}></div>
                            <div className="container">
                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12">
                                   <h1 className="">
                                        {title}   
                                    </h1>
                                   <ol className="custom_breadcrumb">
                                   <li className="breadcrumb-item"><Link to={homePath}>Home</Link></li>
                                        {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.reports_template) && <li className="breadcrumb-item"><a href="#">
                                            {parentMenu.reports_template[0].parentMenu.title}    
                                        </a></li>}
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {title}
                                        </li>
                                    </ol>
                               
                                   </div>
                                 
                                   </div>
                            </div>
                </div>
                <ScrollAnimation animateIn="fadeIn">
             <div className="container">
                <div className="row">
                <div className="col-12-auto">
                    <div className="table-responsive mt-4" style={{maxWidth:"100vw"}}>
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Title</th>
                        <th scope="col">Report</th>
                        <th scope="col">Presentation</th>
                        <th scope="col">Webcast</th>
                        <th scope="col">Audio</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {!isNullUndefined(financial_reports_item) && financial_reports_item.sort(sortItemsByLatestDate).map((item => {
                            let momentDate = getMomentDate(item.date)
                            return (
                                <tr key={item.slug}>
                                    <td>
                                        {`${momentDate.format("Do")} ${momentDate.format("MMM")} ${momentDate.format("YYYY")}`}
                                    </td>
                                    <td>
                                        {item.title || ""}
                                    </td>
                                    <td className="text-center">
                                        <a target="blank" style={{cursor: isNullUndefined(item.report) ? "no-drop":"pointer", filter: isNullUndefined(item.report) ? "grayscale(100%)":"grayscale(0%)"}} onClick={(e) => {
                                            e.preventDefault()
                                            if(isNullUndefined(item.report)){
                                                return;
                                            }
                                            window.open(item.report.file.url, "_blank")
                                        }}>
                                            <img width="40"  className="m-0" src={Pdf_icon}/>
                                        </a>
                                    </td>
                                    <td className="text-center">
                                        <a target="blank" style={{cursor: isNullUndefined(item.presentation) ? "no-drop":"pointer",  filter: isNullUndefined(item.presentation) ? "grayscale(100%)":"grayscale(0%)"}}  onClick={(e) => {
                                            e.preventDefault()
                                            if(isNullUndefined(item.presentation)){
                                                return;
                                            }
                                            window.open(item.presentation.file.url, "_blank")
                                        }}>
                                            <img width="40"  className="m-0" src={Pdf_icon}/>
                                        </a>
                                    </td>
                                    <td className="text-center">
                                        <a onClick={async e => await showDocument(e, isNullUndefined(item.webcast) ? null : item.webcast.file.url)} style={{cursor: isNullUndefined(item.webcast) ? "no-drop" : "pointer",  filter: isNullUndefined(item.webcast) ? "grayscale(100%)":"grayscale(0%)"}}>
                                            <img width="40"  className="m-0" src={Video_icon} />
                                        </a>
                                    </td>
                                    <td className="text-center">
                                        <a  onClick={async e => await showDocument(e, isNullUndefined(item.audio) ? null : item.audio.file.url)} style={{cursor: isNullUndefined(item.audio) ? "no-drop" : "pointer",  filter: isNullUndefined(item.audio) ? "grayscale(100%)":"grayscale(0%)"}}>
                                            <img width="40"  className="m-0" src={Mic_icon}/>
                                        </a>
                                    </td>
                                </tr>
                            )
                        }))}
                    </tbody>
                    </table>
                    </div>
                   
                </div>
                 </div>
                </div>
             </ScrollAnimation>
             </Layout>
    </TemplateWrapper>
  );
}

export default ReportsTemplatePageInvestor