
import { Link, graphql } from "gatsby";
import React from 'react';
import styles from "../../components/innerPage.module.css"
import Layout from '../../components/Investor/layout';
import ScrollAnimation from 'react-animate-on-scroll';
import { isNullUndefined, capitalizeFirstLetter} from "../../util"
import SEO from "../../components/seo"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

export const pageQueryIframeTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulIframeTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            bannerImage {
                file {
                    url
                }
            }
            spaceId
            slug
            title
            iframeLink
            parentMenu {
              iframe_template {
                parentMenu {
                  title
                  slug
                }
              }
          }
        }
    }
`

const IframeTemplatePageInvestor = (props) => {

  const {bannerImage, slug, title, spaceId, iframeLink, parentMenu} = props.data.contentfulIframeTemplate;
  React.useEffect(() => {
  }, []);
  return (
    <TemplateWrapper>
      <Layout>
          <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
          
                <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
                <div className={styles.overlay}></div>
                            <div className="container">
                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12">
                                  <h1 className="">{title}</h1> 
                                   <ol className="custom_breadcrumb">
                                       <li className="breadcrumb-item"><Link to="/Investor/home">Home</Link></li>
                                       {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.iframe_template) && <li className="breadcrumb-item"><a href="#">
                                            {parentMenu.iframe_template[0].parentMenu.title}    
                                        </a></li>}
                                        <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                    </ol>
                               
                                   </div>
                                 
                                   </div>
                            </div>
                </div>
                <ScrollAnimation animateIn="fadeIn">
             <div className="container">
             <div className={styles.iframe_content}>
                  <iframe src={iframeLink}></iframe>
               </div>
             </div>
             </ScrollAnimation>
             </Layout>
    </TemplateWrapper>
  );
}

export default IframeTemplatePageInvestor;