import React from 'react';
import Layout from "../../components/Investor/layout";
import SEO from "../../components/seo"
import styles from "../../components/Investor/news.module.css"
import { graphql,  Link } from 'gatsby';
import { getMomentDate, addDisclaimersToCmsLinks, richTextOptions, isNullUndefined, capitalizeFirstLetter} from "../../util/"
import { useSelector, useDispatch } from 'react-redux';
import {showInvestorApplyOnlinePopup} from "../../actions/investor/prompts/careers/apply-online"
import {showInvestorEmailFriendPopup} from "../../actions/investor/prompts/careers/email-friend"
import {showInvestorDisclaimerPopup} from "../../actions/investor/prompts/disclaimers/"
import {toggleInvestorDisclaimerPopup} from "../../controllers/investor/disclaimer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultInvestorDisclaimerPopupConfigs, config } from '../../util/configs'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

export const pageQueryCareerItem = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulCareersItemTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            description {
                json
            }
            jobTitle
            jobType
            country
            location
            postedOn
            slug
        }
    }
` 

const InvestorCareerItem = (props) => {

    let {jobTitle, jobType, slug, postedOn, country, location, description} = props.data.contentfulCareersItemTemplate;

    let date = getMomentDate(postedOn);

    const dispatch = useDispatch()

    const openApplyOnlinePopup = (e) => {
        let actionConfig = {
            type: "SHOW_INVESTOR_APPLY_ONLINE_POPUP",
            payload: {
                show: true,
                title: null,
                jobPosition: jobTitle,
                jobType: jobType,
                country: country,
                location: location,
                okLabel: "Apply",
                closeLabel: "Cancel",
                okCallback: () => {
                    toast.success("Your application was submitted successfully");
                },
                closeCallback: () => {}
            }
        }

        dispatch(showInvestorApplyOnlinePopup(actionConfig))
    }

    const openEmailFriendPopup = (e) => {
        let actionConfig = {
            type: "SHOW_INVESTOR_EMAIL_FRIEND_POPUP",
            payload: {
                show: true,
                title: "Email Job",
                description: "If you would like to e-mail the selected job to a friend, please fill out the form below. *indicates a required field",
                okLabel: "Send",
                closeLabel: "Cancel",
                queryData: {jobTitle, jobType, slug, postedOn, country, location, description},
                okCallback: () => {
                    toast.success("We emailed your friend this job");
                },
                closeCallback: () => {}
            }
        }

        dispatch(showInvestorEmailFriendPopup(actionConfig));
    }

    let richText = documentToReactComponents(isNullUndefined(description) ? description : description.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showInvestorDisclaimerPopup(actionConfig));
    }
    
    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);


    React.useEffect(() => {
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);
    return ( 
           <TemplateWrapper>
               <Layout>
            
            <SEO title={`${capitalizeFirstLetter(jobTitle)} | Investor or Media Relations`} />
           <ToastContainer
                       position="top-right"
                       autoClose={3500}
                       hideProgressBar={false}
                       newestOnTop={true}
                       closeOnClick
                       rtl={false}
                       pauseOnFocusLoss
                       draggable
                       pauseOnHover
                   />
               <div className={"container"} style={{marginTop: "145px"}}>
                   <div className={"row"}>
                       <div className={"col-12"}>
                           <a style={{cursor: "pointer"}} title="go back" onClick={e => {
                               e.preventDefault();
                               window.history.back();
                           }} className={styles.go_back}>❮</a> 
                           <h2 style={{marginTop: '20px', color: '#8bc63e'}}>{jobTitle}</h2>
                       </div>
                   </div>
   
                  
   
                   <div className={"row"}>
                       <div className={"col-12"}>
                           <hr/>
                           <div className={styles.author}>
                       <h5>Job description</h5> <span className={styles.date}>posted on {`${date.format("Do")} ${date.format("MMMM")} ${date.format("YYYY")}`}</span>
                           </div>
                           <hr/>
                       </div>
                   </div>
   
                   <div className={"row"}>
                       <div className={"col-12"}>
                           <p>
                               <strong>Job type:</strong> {jobType}
                           </p>
                           {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                       </div>
                   </div>
   
                   <button className="custom_btn dark bordered_btn mr-2 mb-2" onClick={openApplyOnlinePopup}>Apply Online</button>
                   <button className="custom_btn dark bordered_btn mb-2" onClick={openEmailFriendPopup}>Email A Friend</button>
               </div>
         </Layout>
           </TemplateWrapper>
     );
}
 
export default InvestorCareerItem;