import React from "react"
import {useDispatch} from "react-redux"
import {graphql, Link} from "gatsby"
import SEO from "../../components/seo"
import ScrollAnimation from "react-animate-on-scroll"
import Bannerstyles from "../../components/innerPage.module.css"
import styles from "../../components/Investor/bannerNews.module.css"
import Layout from '../../components/Investor/layout';
import { deriveHomePathFromSpace, isNullUndefined, addDisclaimersToCmsLinks, richTextOptions, capitalizeFirstLetter, transformMarkdownToReact, replaceNewlinesWithHtmlLineBreaks } from "../../util"
import {showInvestorDisclaimerPopup} from "../../actions/investor/prompts/disclaimers"
import {toggleInvestorDisclaimerPopup} from "../../controllers/investor/disclaimer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultInvestorDisclaimerPopupConfigs, config } from '../../util/configs'
import queryString from "query-string"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

export const pageQueryAdvisersTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulAdvisersTemplate(spaceId: {eq: $spaceId}, slug: {eq: $slug}) {
            title
            slug
            position
            advisersItem {
              title
              description
            }
        }
    }
`

const AdvisersTemplatePageInvestor = (props) => {

    const {title, slug, position, advisersItem} = props.data.contentfulAdvisersTemplate;

    const dispatch = useDispatch()

    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);

    const showExternalLinkDisclaimer = (externalUrl) => {
      let okCallback = () => window.open (externalUrl, "_blank")
      let closeCallback = () => {}
      let actionConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback)
      dispatch(showInvestorDisclaimerPopup(actionConfig));
    }

    React.useEffect(() => {

        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);

    return ( 
        <TemplateWrapper>
          <Layout>
            <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
            <div className={Bannerstyles.banner} style={{background:'url(https://cdn.pixabay.com/photo/2017/07/10/23/43/question-mark-2492009_960_720.jpg)'}}>
            <div className={Bannerstyles.overlay}></div>
                        <div className="container" >
                           <div className="row">
                               <div className="col-12 col-sm-12 col-md-12">
                                  <h1 className="">{title}</h1>
                               <ol className="custom_breadcrumb">
                               <li className="breadcrumb-item"><Link to="/Investor/home">Home</Link></li>
                                    <li className="breadcrumb-item"><a href="#">Investors</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                </ol>
                           
                               </div>
                             
                               </div>
                        </div>
            </div>
            <ScrollAnimation animateIn="fadeIn">
            <div className={styles.content}>
                    <div className="container pt-5 pb-5">
                    <div className="row">
                       
                       {!isNullUndefined(advisersItem) && advisersItem.map((item, index) => {
                           return (
                            <div className={"col-12 col-sm-12 col-md-6"} key={index}>
                            <div className={`${styles.banner_news} ${styles.advisers}`}>
                            <div className="">
                               <div className="card-body">
                                 <h5>
                                     {item.title}
                                 </h5>
                                 <div dangerouslySetInnerHTML={{__html: replaceNewlinesWithHtmlLineBreaks(item.description)}} />
                               </div>
                             </div>
                           </div>
                           
                             </div> 
                           )
                       })}
                </div>
               </div>
           </div>
         </ScrollAnimation>
         </Layout>
        </TemplateWrapper>
     );
}

export default AdvisersTemplatePageInvestor