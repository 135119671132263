import { Link, graphql } from "gatsby";
import React from 'react';
import {useDispatch} from "react-redux"
import styles from "../../components/innerPage.module.css"
import Layout from '../../components/Investor/layout';
import SEO from "../../components/seo"
import ScrollAnimation from 'react-animate-on-scroll';
import {isNullUndefined, capitalizeFirstLetter, richTextOptions, addDisclaimersToCmsLinks, transformMarkdownToReact} from "../../util"
import { getDefaultInvestorDisclaimerPopupConfigs, config } from '../../util/configs'
import {showInvestorDisclaimerPopup} from "../../actions/investor/prompts/disclaimers/"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import queryString from "query-string"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"

const { richTextFromMarkdown } = require('@contentful/rich-text-from-markdown');

export const pageQueryShareInformationTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulShareInformationTemplate(spaceId: {eq: $spaceId}, slug: {eq: $slug}) {
            title
            bannerImage {
                file {
                    url
                }
            }
            iframeLink
            iframeText {
                json
            }
            Markdown {
                childMarkdownRemark {
                    htmlAst
                }
            }
        }
    }
`

const ShareInformationTemplatePageInvestor = (props) => {
    const {title, bannerImage, iframeLink, iframeText, Markdown} = props.data.contentfulShareInformationTemplate;

    const dispatch = useDispatch()

    let iframeRichText = documentToReactComponents(isNullUndefined(iframeText) ? iframeText : iframeText.json, richTextOptions)

    let iframeBodyText = ReactDOMServer.renderToStaticMarkup(iframeRichText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showInvestorDisclaimerPopup(actionConfig));
    }

    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);

    React.useEffect(() => {
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);

    return ( 
        <TemplateWrapper>
            <Layout>
            <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
            <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
            <div className={styles.overlay}></div>
                        <div className="container">
                           <div className="row">
                               <div className="col-12 col-sm-12 col-md-12">
                               <ScrollAnimation animateIn="fadeIn"><h1 className="">{title}</h1></ScrollAnimation> 
                               <ol className="custom_breadcrumb">
                               <li className="breadcrumb-item"><Link to="/Investor/home">Home</Link></li>
                                    <li className="breadcrumb-item"><a href="#">Investors</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                </ol>
                           
                               </div>
                             
                               </div>
                        </div>
            </div>
            <ScrollAnimation animateIn="fadeIn">
            <div className={styles.content}>
                    <div className="container pt-5 pb-5">
                    <div className="row">
                    <div className="col-12 col-sm-12 col-md-5">
                    <div className={styles.shareholder_card}>
                    <h2>Share price</h2>
                        <iframe src={iframeLink}></iframe>
                        </div>
                    </div>
                  
                    <div className="col-12 col-sm-12 col-md-7">
                       
                    {ReactHtmlParser(iframeBodyText, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                    
                     </div>
                 

                </div>
                <div className="row">
                  <div className="col-12">
                  
                  {!isNullUndefined(Markdown) && !isNullUndefined(Markdown.childMarkdownRemark) && !isNullUndefined(Markdown.childMarkdownRemark.htmlAst) && transformMarkdownToReact(Markdown.childMarkdownRemark.htmlAst, showExternalLinkDisclaimer)}

                  </div>
                </div>
                    </div>
           </div>
         </ScrollAnimation>
         </Layout>
        </TemplateWrapper>
     );
}

export default ShareInformationTemplatePageInvestor