import React from 'react';
import styles from "./topstrip.module.css"
const LandingPageTopStrap = () => {
  return (
    <div className={styles.wrap}> 
    <div className="container-fluid text-white text-center py-2">
      <p className="mb-0">Diurnal is now Neurocrine UK</p>
    </div>
    </div>
  );
};

export default LandingPageTopStrap;
